function getAllWithStringInName(arr, name) {
  if (!Array.isArray(arr)) {
    throw new TypeError('Input should be Array');
  }

  if (typeof name !== 'string') {
    throw new TypeError('Size should be a string');
  }

  var result = [];
  arr.forEach((val, index, array) => {
    if(val.node.name.includes(name)){
      result.push(val);
    }
  })

  return result;
};

function getWithStringInName(arr, name) {
  if (!Array.isArray(arr)) {
    throw new TypeError('Input should be Array');
  }

  if (typeof name !== 'string') {
    throw new TypeError('Size should be a string');
  }

  for(var i = 0; i < arr.length; i++){
    if(arr[i].node.name.includes(name)){
      return arr[i];
    }
  }
};

export { getAllWithStringInName, getWithStringInName }