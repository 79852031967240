import React, { useEffect } from "react"
import PropTypes from "prop-types"

import styles from "./parallax.module.scss"
import { isArray } from "util";

const Parallax = ({ children, translateZClass }) => {
  var styleClassModified = translateZClass;
  if (!Array.isArray(translateZClass)) {
    styleClassModified = [translateZClass];
  }
  return <div className={[styles.parallaxLayer, ...styleClassModified].join(' ')}>
    {children}
  </div>
}


export default Parallax
