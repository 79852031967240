import React from "react"
import styles from "./landing.module.scss"
import GatsbyImage from "gatsby-image";
import { Link } from "gatsby";
import globalStyles from "../../services/globalStyles.module.scss"
import logo from "../../images/index/logo.svg"
import Context from "../contextProvider"
import TestComponent from "../testComponent";

const Landing = ({ imgs, hideLogo, heading, subheading, descriptionItems, changeStyle, randomImg, languageCode, actionButton }) => {
  const styleToUse = changeStyle ? changeStyle : styles;

  return <div style={{ overflow: "hidden" }}>
    <Context.Consumer>
      {data => {
        if (data.data.isMobile === false) {
          if (imgs && imgs.length == 5) {
            return <div className={[globalStyles.hideOnMobile, styleToUse.overflowContainer].join(' ')}><div className={styleToUse.sliderPosition}>
              <div className={styleToUse.sliderWrap}>
                <TestComponent imgs={imgs} containerClass={styleToUse.sliderContainer} innerContainerClass={styleToUse.sliderContent} slideClass={styleToUse.slide} />
              </div>
            </div>
            </div>
          }
          else if (randomImg) {
            return <div className={styles.absoluteContainer}>
              <div className={styles.desktopLandingImgContainer}>
                <GatsbyImage
                  fluid={randomImg.node ? randomImg.node.childImageSharp.fluid : randomImg.image.childImageSharp.fluid}
                />
              </div>
            </div>
          }
        }
      }
      }
    </Context.Consumer>
    <div className={[styleToUse.landingContainer, globalStyles.contentContainer2, globalStyles.landingPadding].join(' ')}>
      {randomImg && <GatsbyImage
        className={[styleToUse.landingImgContainer, globalStyles.hideOnDesktop].join(' ')}
        fluid={randomImg.node ? randomImg.node.childImageSharp.fluid : randomImg.image.childImageSharp.fluid}
        loading="eager"
      />}
      <div className={hideLogo ? [styleToUse.formContainer, styleToUse.hideLogo].join(' ') : styleToUse.formContainer}>
        <div className={styleToUse.logo}>
          <Link to={`${languageCode == "en" ? "/" : `/${languageCode}`}`}>
            <img src={logo} className={globalStyles.hideOnMobile} />
          </Link>
        </div>
        <h1 className={styleToUse.landingHeader}>{heading}</h1>
        {subheading && <p className={styleToUse.landingParagraph}>{subheading}</p>}
        {descriptionItems && (
          <ul className={styles.descriptionItems}>
            {descriptionItems.map((d, i) => (
              <li key={i}>{d}</li>
            ))}
          </ul>
        )}
        {actionButton}
      </div>
    </div>
  </div>
}

Landing.propTypes = {

}


export default Landing
