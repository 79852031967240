import React from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import styles from "./index.module.scss"
import reusedStyle from "./infoButton.module.scss"
import globalStyles from "../services/globalStyles.module.scss"
import { greenGradient2 } from "../services/constants"
import planningVideo from "../videos/fp-01_organizationplanning_@2x.mp4"
import InfoBox from "../components/infoBox"
import arrow from "../images/common/icon_arrow.svg"
import playIcon from "../images/common/play_icon.svg"
import Header from "../components/header"
import Footer from "../components/footer"
import { shuffle } from "../services/shuffle"
import comVideo from "../videos/fp-02_communication_@2x.mp4"
import monitoringVideo from "../videos/monitoring_fin_V2.mp4"
import tacticsVideo from "../videos/tactics_and_analysis.mp4"
import Landing from "../components/index/landing"
import ProductPresentationWrap from "../components/index/productPresentationWrap"
import Feature from "../components/index/feature"
import SeeHowItWorks from "../components/index/seeHowItWorks"
import AlwaysWithYou from "../components/index/alwaysWithYou"
import Context from "../components/contextProvider"
import Teams from "../components/index/teams"
import Testimonials from "../components/index/testimonials"
import { langPath } from "../services/languageService"
import GetStarted from "../components/getStarted"
import Button from "../components/button"
import { openModal } from "../services/modal"

class RegistrationShower extends React.Component {
  componentDidMount() {
    if (window.location.pathname.split("/").indexOf("registration") > -1) {
      setTimeout(() => {
        this.props.set({ signUpOpen: true })
      }, 500) // Have to wait for the signup script to be loaded :/
    }
  }
  render() {
    return null
  }
}

class IndexPage extends React.Component {
  static context = Context
  constructor(props) {
    super(props)
    this.state = {
      showSports: false,
      showMessage: false,
      messageTitle: false,
      messageSubtitle: false,
      customerImages: [],
      shuffledLandingImgs: [],
    }
    this.collapseRef = React.createRef()
  }

  showSports(show) {
    this.setState({ showSports: show })
  }
  btnClick(sport) {
    const { languageCode } = this.props
    this.showSports(false)
    this.linkClicked("/sport")
    const link = languageCode
      ? languageCode == "en"
        ? "/sport/?type=" + sport
        : `/${languageCode}/sport/?type=${sport}`
      : "/sport/?type=" + sport
    navigate(link, { state: { sport } })
  }

  linkClicked(urlParam) {
    const location = document.location.href.split("/")
    if (
      this.refs.checkbox &&
      urlParam === "/" + location[location.length - 1]
    ) {
      this.refs.checkbox.checked = false
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  mobileSportsClicked() {
    var content = this.collapseRef.current
    if (content.style.maxHeight) {
      content.style.maxHeight = null
    } else {
      content.style.maxHeight = content.scrollHeight + "px"
    }
  }

  handleSubmit = (e, set) => {
    e.preventDefault()
    const form = e.target
    set({
      signUpOpen: true,
    })
    let email = form.childNodes[1].value
    form.reset()
    setTimeout(() => {
      XPSSignup["_email"](email)
      document.querySelectorAll("input#name")[0].focus()
    })
  }

  componentWillMount() {
    const { landingImgs } = this.props.data

    const shuffledLandingImgs = shuffle(landingImgs.edges)
    this.setState({
      shuffledLandingImgs,
    })
  }

  render() {
    const {
      texts: { _ },
      customerImgs,
      products,
      sportContent,
    } = this.props.data

    const languageCode =
      this.props.pageContext && this.props.pageContext.lang
        ? this.props.pageContext.lang
        : "en"

    const link = langPath(languageCode)

    const { showSports, showMessage, shuffledLandingImgs } = this.state

    return (
      <Layout
        header={
          <Header
            num={1}
            translations={_}
            headerFullYOffset={600}
            mobileHeaderFullYOffset={40}
            fixed
            languageCode={languageCode}
          />
        }
        translations={_}
        languageCode={languageCode}
      >
        <Landing
          imgs={shuffledLandingImgs}
          styles={styles}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          hideOverlay={() => {
            this.setState({ showMessage: false })
          }}
          hideLogo
          heading={_.index.heading}
          subheading={_.index.subheading}
          descriptionItems={_.index.description_items}
          enter_your_email={_.enter_your_email}
          success_title={_.contact_message.success_title}
          success_subtitle={_.contact_message.success_subtitle}
          languageCode={languageCode}
          randomImg={shuffledLandingImgs[0]}
          actionButton={
            <>
              <Button
                onClick={() => navigate(link("/watchNow"))}
                title={_.contact_message.success_title}
                subtitle={_.contact_message.success_subtitle}
                showMessage={showMessage}
                hideOverlay={() => {
                  this.setState({ showMessage: false })
                }}
                icon={playIcon}
                classStyle={styles.watchButton}
              >
                {_.index.xps_in_30_seconds}
              </Button>
              <Button onClick={openModal} classStyle={styles.tryXPSButton}>
                {_.try_xps.try_xps_for_free}
              </Button>
            </>
          }
        />
        <ProductPresentationWrap
          translations={_}
          styles={styles}
          img={products}
        />
        <Feature
          backgroundStyle={styles.featureBackgroundPlanning}
          contentContainerClass={styles.planningContent}
          translations={_}
          noClipping
          header={_.index.planning_and_organization_heading}
          items={_.index.planning_and_organization_items}
          img={
            <video
              className={styles.video}
              id="video"
              muted
              autoPlay
              playsInline
              loop
            >
              <source src={planningVideo} type="video/mp4" />
            </video>
          }
        />
        <Feature
          backgroundStyle={styles.featureBackgroundCommunication}
          contentContainerClass={styles.communicationContent}
          imageContainerStyle={styles.featureImageCommunication}
          rightClip
          order
          translations={_}
          header={_.index.communication_heading}
          items={_.index.communication_items}
          img={
            <video
              className={styles.video}
              id="video"
              muted
              autoPlay
              playsInline
              loop
            >
              <source src={comVideo} type="video/mp4" />
            </video>
          }
        />
        <Feature
          backgroundStyle={styles.featureBackgroundAnalysis}
          contentContainerClass={styles.analysisContent}
          show
          noClipping
          translations={_}
          header={_.index.analysis_heading}
          items={_.index.analysis_items}
          img={
            <video
              className={styles.video}
              id="video"
              muted
              autoPlay
              playsInline
              loop
            >
              <source src={monitoringVideo} type="video/mp4" />
            </video>
          }
        />
        <Feature
          backgroundStyle={styles.featureBackgroundTactics}
          contentContainerClass={styles.tacticsContent}
          show
          rightClip
          order
          translations={_}
          header={_.index.tactics_heading}
          items={_.index.tactics_items}
          img={
            <video
              className={styles.video}
              id="video"
              muted
              autoPlay
              playsInline
              loop
            >
              <source src={tacticsVideo} type="video/mp4" />
            </video>
          }
        />
        <SeeHowItWorks
          translations={_}
          title={_.index.complete_solution_heading}
        />
        <AlwaysWithYou translations={_} />
        <Teams
          imgs={customerImgs}
          translations={_}
          languageCode={languageCode}
        />
        <Testimonials
          testimonials={[
            {
              location: "NBA Los Angeles Clippers",
              quote:
                "You don't go to Basketball Heaven without using XPS Network.",
              avatar:
                "https://blog.sidelinesports.com/wp-content/uploads/2020/09/john-welch.jpg",
              title: "John Welch",
              subtitle: "Assistant Coach, Basketball",
            },
            {
              location: "Icelandic Men's National Football Team",
              quote:
                "For all coaches that want to be constantly improving, the XPS is a great tool.",
              avatar:
                "https://blog.sidelinesports.com/wp-content/uploads/2020/09/heimir.png",
              title: "Heimir Hallgrímsson",
              subtitle: "Head Coach, Soccer",
            },
          ]}
        />
        <div className={styles.featureDetailContainer}>
          <div className={styles.featureDetailWrap}>
            <div className={styles.infoBoxContainer}>
              <InfoBox
                classStyle={styles.infoBox}
                gradient={greenGradient2}
                mobileImg
                imgNumber={2}
              >
                <h1
                  style={{ color: "#3C434C" }}
                  className={styles.infoBoxHeader}
                >
                  {_.index.thousands_of_coaches}
                </h1>
                <div className={reusedStyle.buttonContainer}>
                  <button className={reusedStyle.infoButton}>
                    <div
                      className={reusedStyle.infoButtonContentContainer}
                      onClick={() => {
                        navigate(link("/testimonials"))
                      }}
                    >
                      <div className={styles.infoButtonTextContainer}>
                        {_.index.see_what_theyre_saying}
                      </div>
                      <div
                        className={[
                          reusedStyle.infoButtonIconContainer,
                          globalStyles.hideOnMobile,
                        ].join(" ")}
                      >
                        <img
                          style={{
                            marginLeft: 14,
                            marginTop: -4,
                            width: 32,
                            height: 32,
                          }}
                          src={arrow}
                          alt="Video"
                        />
                      </div>
                      <div
                        onClick={() => {
                          navigate(link("/testimonials"))
                        }}
                        className={[
                          reusedStyle.infoButtonIconContainer,
                          globalStyles.hideOnDesktop,
                        ].join(" ")}
                      >
                        <img
                          style={{ marginLeft: "auto", width: 16, height: 16 }}
                          src={arrow}
                          alt="Video"
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </InfoBox>
            </div>
          </div>
        </div>
        <GetStarted languageCode={languageCode} translations={_} />
        <Header
          num={2}
          className={globalStyles.hideOnMobile}
          translations={_}
          headerFullYOffset={600}
          boxShadow
          languageCode={languageCode}
        />
        <Footer translations={_} languageCode={languageCode} />
        <Context.Consumer>
          {({ set }) => <RegistrationShower set={set} />}
        </Context.Consumer>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query Index($langFile: String) {
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        ...ProductPresentationTranslations
        ...TryXpsModalTranslations
        time_to_get_started
        enter_your_email
        start_free_trial
        sports {
          basketball
          field_hockey
          floorball
          handball
          ice_hockey
          other_sports
          performance
          soccer
          tennis
          volleyball
        }
        always_with_you {
          title
          description
        }
        index {
          heading
          subheading
          description_items
          xps_in_30_seconds
          complete_solution_heading
          see_how_it_works
          planning_and_organization_heading
          planning_and_organization_items
          communication_heading
          communication_items
          analysis_heading
          analysis_items
          tactics_heading
          tactics_items
          trusted_title
          trusted_heading
          trusted_paragraph
          see_what_theyre_saying
          good_company
          thousands_of_coaches
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
    sportContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/sportsContent/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            icon {
              name
              extension
              relativePath
              publicURL
            }
          }
        }
      }
    }
    products: file(
      relativePath: { eq: "index/productPresentation/products.webp" }
    ) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    customerImgs: allFile(filter: { relativePath: { glob: "tmp/*" } }) {
      edges {
        node {
          name
          extension
          relativePath
          childImageSharp {
            fluid(maxWidth: 893) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    landingImgs: allFile(
      filter: { relativePath: { glob: "index/landingCarousel2/*" } }
    ) {
      edges {
        node {
          name
          extension
          relativePath
          publicURL
          childImageSharp {
            fluid(maxWidth: 893) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
