import React from "react"
import globalStyles from "../../services/globalStyles.module.scss"
import styles from "./feature.module.scss"
import ClipContainer from "../clipContainer";
import { useInView } from "react-intersection-observer";

const Feature = ({ img, header, items, rightClip, order, backgroundStyle, imageContainerStyle, noClipping, contentContainerClass }) => {
  const { ref, inView } = useInView({
    rootMargin: "200px",
    triggerOnce: true
  })

  return <div ref={ref}>
    <ClipContainer
      style={{
        position: "relative",
      }}
      none={noClipping}
      bottom
      right={rightClip}
      className={[styles.featureBackground, backgroundStyle].join(' ')}>
      <div className={globalStyles.hideOnDesktop}>
        <div className={styles.featureHeader}>{header}</div>
        {items.map((val, index) => {
          return <div key={index} className={[styles.featureItem, index === (items.length - 1) && globalStyles.noBorder].join(' ')}>
            {val}
          </div>
        })}
      </div>
      <div className={[styles.featureDetailContainer, globalStyles.contentPaddingContainer2].join(' ')}>
        <div className={[styles.featureDetailWrap, contentContainerClass].join(' ')}>
          <div className={styles.featureDetail}>
            <div style={order && { order: 2 }} className={[styles.featureImageContainer, imageContainerStyle].join(' ')}>
              <div className={[styles.flexCenter].join(' ')}>
                {inView && img}
              </div>
            </div>
            <div className={styles.featureTextContainer}>
              <div className={styles.featureHeader}>{header}</div>
              {items.map((val, index) => {
                return <div key={index} className={[styles.featureItem, index === (items.length - 1) && globalStyles.noBorder].join(' ')}>{val}</div>
              })}
            </div>
          </div>
        </div>
      </div>
    </ClipContainer>
  </div>
}

Feature.propTypes = {

}


export default Feature
