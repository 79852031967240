import React, { createRef } from "react"
import PropTypes from "prop-types"
import styles from "./testimonial.module.scss"
import Image from "gatsby-image";
import Button from "./button"
import play from "../images/common/play_icon.svg"
import Cinema from "./cinema";

class Testimonial extends React.Component {
  constructor(props) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
    this.video = createRef();
  }

  componentWillMount(){
    this.setState({video: this.props.video});
  }

  onButtonClick() {
    if(this.video.current && this.video.current.src === 'about:blank'){
      this.video.current.src = this.props.video + "?autoplay=1";
    }
  }

  hideOverlay() {
    if(this.video.current){
      this.video.current.src = 'about:blank';
    }
  }

  render() {
    const { location, quote, avatar, title, subtitle, video } = this.props;
    return (
      <div className={styles.testimonial}>
        <h3 className={styles.location}>{location}</h3>
        <p className={styles.quote}>“{quote}”</p>
        <div className={styles.avatarAndTitle}>
          <div className={styles.avatarContainer}>
            {avatar &&
              <img 
                src={avatar}
                className={styles.avatar} 
                loading="lazy"
                />
            }
          </div>

          <div className={styles.titleContainer}>
            <h3 className={styles.name}> {title} </h3>
            <h3 className={styles.role}> {subtitle} </h3>
            <div className={styles.buttonContainer}>
              {video &&
              <Button
                hideOverlay={() => this.hideOverlay()}
                onClick={() => this.onButtonClick()}
                cinema={video &&
                  <iframe
                    ref={this.video}
                    src={video + "?autoplay=1"}
                    frameBorder="0"
                    allowFullScreen
                    allow="autoplay"
                    className={styles.video} />}
                    classStyle={styles.button}>
                      <div>
                        <img className={styles.videoImg} src={play} alt="play" />
                        <span>
                          WATCH VIDEO
                        </span>
                        </div>
              </Button>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Testimonial.propTypes = {
  location: PropTypes.string,
  quote: PropTypes.string,
  avatar: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Testimonial
