import React from "react"
import globalStyles from "../../services/globalStyles.module.scss"
import styles from "./teams.module.scss"
import TextSlider from "../textSlider";
import TeamsAnimation from "../teamsAnimation";
import arrow from "../../images/common/icon_arrow.svg"
import Context from "../contextProvider";
import { navigate } from "gatsby";

const Teams = ({ translations, imgs, languageCode }) => {
  const langPath = languageCode === 'en' ? '/' : languageCode + '/';
  return <div className={styles.container}>
    <div className={[globalStyles.hideOnDesktop, styles.nationalSection].join(' ')}>
      <div className={styles.featureTitle}>{translations.index.trusted_title}.</div>
      <div className={styles.featureNationalHeader}>
        <div>
          <TextSlider
            innerWrapStyle={styles.textSliderInnerWrap}
            outerWrapStyle={styles.textSliderOuterWrap}
            containerStyle={styles.textSliderContainer}
            outOfSliderStyle={styles.outOfTextSlider}
            texts={translations.index.trusted_heading}
            slideClass={styles.nationalTextSlide}
            displayDuration={3000}
            slideDuration={2000} />
        </div>
      </div>
      <div className={globalStyles.hideOnDesktop}>
        <div className={styles.featureParagraph}>
          {translations.index.trusted_paragraph}
        </div>
        <div className={styles.infoButtonContentContainer} onClick={() => navigate(`/customers`)}>
          <div className={styles.nationalButtonTextContainer}>
            {translations.index.good_company}
          </div>
          <div
            className={styles.infoButtonIconContainer}>
            <img
              style={{ marginLeft: "auto", width: 16, height: 16 }}
              src={arrow}
              alt="Video"
            />
          </div>
        </div>
      </div>
    </div>
    <div style={{ position: "relative" }} className={[styles.featureDetailContainer, styles.featureDetailContainerNational].join(' ')}>
      <div className={styles.featureDetailWrap}>
        <div className={styles.featureDetail}>
          <div className={styles.nationalBackgroundShape}>
            <Context.Consumer>
              {(data) => {
                return data.data.isMobile && <div className={[styles.sliderContainer, globalStyles.hideOnDesktop].join(' ')}>
                  <TeamsAnimation imgs={imgs} containerClass={styles.nationalSwiperWrap} />
                </div>
              }}
            </Context.Consumer>
          </div>
          <div className={[styles.featureTextContainer, styles.featureTextNational].join(' ')}>
            <div className={styles.featureTitle}>{translations.index.trusted_title}.</div>
            <div className={styles.featureHeader}>
              <div className={styles.nationalSwipeWrap}>
                <TextSlider
                  innerWrapStyle={styles.textSliderInnerWrap}
                  outerWrapStyle={styles.textSliderOuterWrap}
                  containerStyle={styles.textSliderContainer}
                  outOfSliderStyle={styles.outOfTextSlider}
                  texts={translations.index.trusted_heading}
                  slideClass={styles.nationalTextSlide}
                  displayDuration={3000}
                  slideDuration={2000} />
              </div>
            </div>
            <div className={styles.featureItem} style={{ fontWeight: "normal" }}>
              <p>
              {translations.index.trusted_paragraph}
              </p>
              <div className={[styles.infoButtonContentContainer, styles.infoButtonNational].join(' ')} onClick={() => navigate(`customers`)}>
                <div className={styles.infoButtonTextContainer}>{translations.index.good_company}</div>
                <div
                  className={[
                    styles.infoButtonIconContainer,
                  ].join(" ")}
                >
                  <img
                    style={{ marginLeft: 14, marginTop: -4, width: 32, height: 32 }}
                    src={arrow}
                    alt="Video"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={[styles.sliderContainer, globalStyles.hideOnMobile].join(' ')}>
        <Context.Consumer>
          {(data) => {
            return !data.data.isMobile && <TeamsAnimation imgs={imgs} containerClass={styles.nationalSwiperWrap} />
          }}
        </Context.Consumer>
      </div>
    </div>
  </div>
}

Teams.propTypes = {

}


export default Teams
