import React from "react"
import styles from "./productPresentation.module.scss"
import globalStyles from "../services/globalStyles.module.scss";
import { graphql, StaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image";
import ClipContainer from "./clipContainer";

const ProductPresentation = ({ translations, className, data }) => {
  const { background, mobileImg } = data;
  return <div className={styles.container}>
           <ClipContainer right bottom className={[styles.featureDetailBackgroundApplication].join(' ')}>
             <GatsbyImage className={styles.backgroundImage} fluid={background.childImageSharp.fluid} />
             <div className={globalStyles.contentContainer3}>
               <div className={[styles.contentContainer, className].join(' ')}>
                 <h1>{translations.header}</h1>
                 <p className={styles.description}>{translations.footnote}</p>
                 <h3>{translations.bullet_5}</h3>
                 <h3>{translations.bullet_1}</h3>
                 <h3>{translations.bullet_2}</h3>
                 <h3 className={styles.noBorder}>{translations.bullet_4}</h3>
                 <GatsbyImage
                   className={[styles.mobileImgContainer, globalStyles.hideOnDesktop].join(' ')}
                   fluid={mobileImg.childImageSharp.fluid}>
                 </GatsbyImage>
               </div>
             </div>
           </ClipContainer>
         </div>
}

ProductPresentation.propTypes = {

}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: {eq: "index/productPresentation/background.webp" }) {
          publicURL
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        mobileImg: file(relativePath: {eq: "index/productPresentation/products.webp" }) {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1542) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ProductPresentation data={data} {...props} />}
  />
)

export const query = graphql`
fragment ProductPresentationTranslations on TextsYaml{
  product_presentation {
    header
    footnote
    bullet_1
    bullet_2
    bullet_4
    bullet_5
  }
}
`
