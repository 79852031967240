import React, { useState } from "react"

import styles from "./teamsAnimation.module.scss"
import { getAllWithStringInName } from "../services/queryResultManipulation";
import ElementSlider from "./elementSlider";
import globalStyles from "../services/globalStyles.module.scss"
import useInterval from "./useInterval";

const TeamsAnimation = ({ imgs, containerClass }) => {
  const [currOffsetIndex, setCurrOffsetIndex] = useState(0);
  const collegeImgs = getAllWithStringInName(imgs.edges, "college");
  const nationalImgs = getAllWithStringInName(imgs.edges, "national");
  const highSchoolImgs = getAllWithStringInName(imgs.edges, "high_school");
  const proTeamsImgs = getAllWithStringInName(imgs.edges, "pro_team");
  const federationImgs = getAllWithStringInName(imgs.edges, "federation");
  const youthClubImgs = getAllWithStringInName(imgs.edges, "youth_club");

  const sliderparams = {
    spaceBetween: 100,
    direction: 'vertical',
    loop: true,
    noSwiping: true
  }

  var mergedElements = [[], [], [], [], [], []];
  for (var i = 0; i < collegeImgs.length; i++) {
    mergedElements[i].push(proTeamsImgs[i]);
    mergedElements[i].push(nationalImgs[i]);
    mergedElements[i].push(federationImgs[i]);
    mergedElements[i].push(collegeImgs[i]);
    mergedElements[i].push(youthClubImgs[i]);
    mergedElements[i].push(highSchoolImgs[i]);
  }
  useInterval(() => {
    if(currOffsetIndex < mergedElements.length + 1){
      var currOffsetIndexTmp = currOffsetIndex;
      currOffsetIndexTmp++;
      setCurrOffsetIndex(currOffsetIndexTmp);
    }
  }, 100);

  return <div className={[styles.container, containerClass, globalStyles.avoidClicks].join(' ')}>
    {mergedElements.map((val, index) => {
      return index + 1 < currOffsetIndex ? <div key={index} className={styles.tile}>
        <div className={styles.swiperContainer}>
          <ElementSlider
            displayDuration={3000}
            slideDuration={1000}
            containerClass={styles.swiperWrap}
            swiperParams={sliderparams}>
            {val.map((val, index) => {
              return <img key={index} className={styles.image} src={val.node.childImageSharp.fluid.src} loading="lazy"/>
            })}
          </ElementSlider>
        </div>
      </div> : null;
    })}
  </div>
}

TeamsAnimation.propTypes = {

}


export default TeamsAnimation
