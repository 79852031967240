import React from "react"
import globalStyles from "../../services/globalStyles.module.scss"
import ProductPresentation from "../productPresentation";
import Parallax from "../parallax";
import GatsbyImage from "gatsby-image";

import styles from "./productPresentationWrap.module.scss"

const ProductPresentationWrap = ({ translations, img, id }) => {


  return <div className={styles.overflowHiddenOnDesktop} style={{ position: "relative"}} id={id}>
    <ProductPresentation translations={translations.product_presentation} className={globalStyles.contentContainer2} />
    <GatsbyImage
      className={[styles.productImgContainer,globalStyles.hideOnMobile].join(' ')}
      fluid={img.childImageSharp.fluid} />
  </div>

}

export default ProductPresentationWrap
