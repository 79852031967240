import React, {useState} from "react"
 import PropTypes from "prop-types"

import styles from "./testComponent.module.scss"
import useInterval from "./useInterval";
import GatsbyImage from "gatsby-image";

const TestComponent = ({imgs, containerClass, innerContainerClass, slideClass }) => {
  const [ refs, setRefs ] = useState(0);
  const [ reverse, setReverse] = useState(0);
  const [ checked, setChecked] = useState(0);

  useInterval(() => {
    if(checked !== 4){
      setChecked(checked + 1);
    }
  }, 10000);

  return (
      <div className={[styles.slider, containerClass].join(' ')}>
        <input ref={ref =>{ var tmprefs = refs ? refs : []; tmprefs.push(ref); setRefs(tmprefs)}} type="radio" name="slider" title="slide1" checked={checked === 0} onChange={() => {}}className={styles.slider__nav} />
        <input ref={ref =>{ var tmprefs = refs ? refs : []; tmprefs.push(ref); setRefs(tmprefs)}} type="radio" name="slider" title="slide2" checked={checked === 1} onChange={() => {}}className={styles.slider__nav} />
        <input ref={ref =>{ var tmprefs = refs ? refs : []; tmprefs.push(ref); setRefs(tmprefs)}} type="radio" name="slider" title="slide3" checked={checked === 2} onChange={() => {}}className={styles.slider__nav} />
        <input ref={ref =>{ var tmprefs = refs ? refs : []; tmprefs.push(ref); setRefs(tmprefs)}} type="radio" name="slider" title="slide4" checked={checked === 3} onChange={() => {}}className={styles.slider__nav} />
        <input ref={ref =>{ var tmprefs = refs ? refs : []; tmprefs.push(ref); setRefs(tmprefs)}} type="radio" name="slider" title="slide5" checked={checked === 4} onChange={() => {}}className={styles.slider__nav} />
        <div className={[styles.slider__inner, innerContainerClass].join(' ')}>
          {imgs.map((img, index) => {
            return <div key={index} className={styles.slider__contents}><i className={[styles.slider__image, styles.fa, styles.fa_codepen].join(' ')}></i>
              <GatsbyImage
                critical
                fadeIn
                fluid={img.node ? img.node.childImageSharp.fluid : img.image.childImageSharp.fluid}
                className={[styles.image, slideClass].join(' ')} />
             </div>
          })}
        </div>
      </div>
  )
}

TestComponent.propTypes = {

}


export default TestComponent
