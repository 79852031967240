import React, { useState } from "react"
import { useInView } from "react-intersection-observer"

import styles from "./alwaysWithYou.module.scss"
import ClipContainer from "../clipContainer"
import appStore from "../../images/index/Download_on_the_App_Store_Badge.svg"
import googlePlay from "../../images/index/Google_Play_Store_badge_EN.svg"
import appStoreQR from "../../images/index/app-store-qr.svg"
import googlePlayQR from "../../images/index/google-play-qr.svg"
import videoPoster from "../../images/index/video-poster.webp"
import appVideo from "../../videos/XPS_App.mp4"

const AlwaysWithYou = ({ translations }) => {
  const { ref, inView } = useInView({
    rootMargin: "200px",
    triggerOnce: true,
  })

  const [videoClicked, setVideoClicked] = useState(false)

  return (
    <ClipContainer right={true} className={styles.container}>
      <h2>{translations.always_with_you.title}</h2>
      <p>{translations.always_with_you.description}</p>
      <div ref={ref} onClick={() => setVideoClicked(true)}>
        <video
          preload={inView ? "auto" : "none"}
          controls={videoClicked}
          poster={videoPoster}
          className={{ [styles.clicked]: videoClicked }}
        >
          <source src={appVideo} type="video/mp4" />
        </video>
      </div>
      <div className={styles.downloads}>
        <img className={styles.qr} src={appStoreQR} alt="app store QR" />
        <a
          href="https://apps.apple.com/app/xps-network/id878771840"
          target="_blank"
        >
          <img
            className={styles.storeDownload}
            src={appStore}
            loading="lazy"
            alt="download on app store"
          />
        </a>
        <img className={styles.qr} src={googlePlayQR} alt="google play QR" />
        <a
          href="https://play.google.com/store/apps/details?id=com.xpsnetwork.android"
          target="_blank"
        >
          <img
            className={styles.storeDownload}
            src={googlePlay}
            loading="lazy"
            alt="get in on google play"
          />
        </a>
      </div>
    </ClipContainer>
  )
}

export default AlwaysWithYou
