import React, { useState, useEffect, useRef } from 'react';

import Swiper from "react-id-swiper"
import useInterval from './useInterval';

const ElementSlider = ({ children, swiperParams, displayDuration, slideDuration, containerClass, stopSlide, reverseDirection }) => {
  const swiper = useRef(null);
  const [mount, setMount] = useState(null);
  const goNext = () => {
    if (swiper.current !== null && swiper.current.swiper !== null && mount) {
      !reverseDirection ? swiper.current.swiper.slideNext(slideDuration) : swiper.current.swiper.slidePrev(slideDuration);
    }
  };
  const params = swiperParams ? swiperParams : {
    slidesPerView: 1,
    direction: "vertical",
    loop: true,
    noSwiping: true
  }

  useEffect(() => {
    setMount(true);
    return () => setMount(false);
  })

  useInterval(() => {
    !stopSlide && mount && goNext();
  }, displayDuration ? displayDuration : 2000);

  return (
    <Swiper ref={swiper} {...params} containerClass={containerClass}>
      {children}
    </Swiper>
  )
}

ElementSlider.propTypes = {

}


export default ElementSlider
