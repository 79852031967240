import React from "react"
import PropTypes from "prop-types"
import styles from "./clipContainer.module.scss"

class ClipContainer extends React.Component {
  constructor(props){
    super(props)
    this.state={
      mobile: false
    }
  }

  componentDidMount(){
    this.setState({mobile: document.documentElement.clientWidth < 810});
  }

  render() {
    //children, sizeOfCut, left, style, styleClass, topCut, bottomCut, reverse, grayBottom, leftAndRight, zIndex, zIndexAfter, zIndexBefore
    /*return <div style={{ ...style }} className={[smallStyle, smallTopStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}> {children} </div>;
  }
  return <div style={{ ...style }} className={[smallStyle, grayBottom && styles.grayBottom, ...styleClassModified].join(' ')}>{children}</div>;
*/

    const { sizeOfCut, tl, tr, br, bl, className, children, style, right, bottom, none} = this.props;

    const clipping = !none && bottom ? (right ? styles.clipTopAndBottomRight : styles.clipTopAndBottomLeft) : (right ? styles.clipPathRight : styles.clipPathLeft);

    return <div className={[className, clipping].join(' ')} style={{...style}}>{children}</div>
  }
}

ClipContainer.propTypes = {
  children: PropTypes.node.isRequired,
  sizeOfCut: PropTypes.number,
  left: PropTypes.bool,
  style: PropTypes.object,
  styleClass: PropTypes.string || PropTypes.array
}

export default ClipContainer
