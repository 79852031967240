import React from "react"
import { openModal } from "../../services/modal"
import styles from "./seeHowItWorks.module.scss"
import Button from "../button"

const SeeHowItWorks = ({ translations, title }) => {
  return (
    <div className={styles.content}>
      {title && <h2 className={styles.seeHowItWorksTitle}>{title}</h2>}
      <div>
        <Button onClick={openModal}>
          {translations.try_xps.try_xps_for_free}
        </Button>
        <p>{translations.try_xps.no_credit_card}</p>
      </div>
    </div>
  )
}

export default SeeHowItWorks
