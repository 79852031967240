import React from "react"
 import PropTypes from "prop-types"

import styles from "./testimonials.module.scss"
import CutContainer from "../cutContainer";
import Testimonial from "../testimonial";

const Testimonials = ({testimonials}) => {
  return <div className={[styles.featureBackground, styles.featureBackgroundTestimonials].join(' ')}>
  <div className={styles.featureDetailContainer}>
    <div className={styles.featureDetailWrap}>
      <div className={styles.testimonials}>
        {testimonials.map((testimonial, index) => {
          return <div key={index} className={styles.testimonial}>
          <Testimonial
            location={testimonial.location}
            quote={testimonial.quote}
            avatar={
              testimonial.avatar
            }
            title={testimonial.title}
            subtitle={testimonial.subtitle}
          />
        </div>
        })}
      </div>
    </div>
  </div>
</div>
}

Testimonials.propTypes = {

}


export default Testimonials
