import React, { useState, useEffect, useRef } from 'react';

import styles from "./textSlider.module.scss"
import globalStyles from "../services/globalStyles.module.scss"
import Translate from "./translate";
import Swiper from "react-id-swiper"
import useInterval from './useInterval';

const count = (str) => {
  const re = /<\/\d>/g
  return ((str || '').match(re) || []).length;
}

const TextSlider = ({ texts, className, children, slideClass, displayDuration, slideDuration, delay, outerWrapStyle, innerWrapStyle, containerStyle, outOfSliderStyle }) => {
  const swiper = useRef(null);
  const [mount, setMount] = useState(null);

  const params = {
    slidesPerView: 1,
    spaceBetween: 100,
    direction: "vertical",
    wrapperClass: innerWrapStyle ? innerWrapStyle : styles.wrap2,
    containerClass: containerStyle ? containerStyle : styles.container2,
    loop: true,
    noSwiping: true
  }
  var elements = [];
  for (var i = 0; i < count(texts); i++) {
    elements.push(<div key={i} className={["swiper-slide", slideClass].join(' ')} />);
  }

  const goNext = () => {
    if (swiper.current !== null && swiper.current.swiper !== null && mount) {
      swiper.current.swiper.slideNext(slideDuration);
    }
  };
  useEffect(() => {
    setMount(true);
    return () => setMount(false);
  })

  useInterval(() => {
    mount && goNext();
  }, displayDuration ? displayDuration : 2000);

  return (
    <Translate
      className={[outerWrapStyle ? outerWrapStyle : styles.wrap, className, globalStyles.avoidClicks].join(' ')}
      nested
      template={texts}
      wrap={<Swiper ref={swiper} {...params} />}
      noneMarkedWrap={<div className={outOfSliderStyle ? outOfSliderStyle : styles.outsideOfSlider} />}
    >
      {children ? children : elements}
    </Translate>
  )
}

export default TextSlider
